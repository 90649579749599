import React, { useState } from 'react';

export const CounterCart = ({ productId, quantity, onUpdateQuantity }) => {
  const [count, setCount] = useState(quantity);

  const handleIncrement = () => {
    const newCount = count + 1;
    setCount(newCount);
    onUpdateQuantity(productId, newCount);
  };

  const handleDecrement = () => {
    if (count > 1) {
      const newCount = count - 1;
      setCount(newCount);
      onUpdateQuantity(productId, newCount);
    }
  };

  const handleChange = (event) => {
    const newCount = parseInt(event.target.value);
    setCount(newCount);
    onUpdateQuantity(productId, newCount);
  };

  return (
    <div className="containerSubAndAdd">
      <button className="btnSubQuantity" onClick={handleDecrement}>-</button>
      <p className="quantityProduct">{count}</p>
      <button className="btnAddQuantity" onClick={handleIncrement}>+</button>
    </div>
  );
};
