import React, { useState, useRef, useEffect } from 'react';
import QRCode from 'react-qr-code';
import { SideBar } from '../SideBar/SideBar';
import iconQR from '../../../Images/qr.svg.png';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
const crypto = require("crypto-js");

export const MainGenerateQR = () => {
  const [quantity, setQuantity] = useState(null);
  const [qrcodes, setQRCodes] = useState([]);
  const qrCodeRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleQuantityChange = (event) => {
    const quantityValue = parseInt(event.target.value, 10);
    setQuantity(quantityValue);
  };

  const handleDownload = async () => {
    setIsLoading(true);

    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      compress: true,
      lineHeight: 1.2,
      fontSize: 11,
    });

    const columns = 3;
    const rows = Math.ceil(qrcodes.length / columns);

    const pageWidth = doc.internal.pageSize.getWidth();
    const gridWidth = columns * 60;
    const marginLeft = (pageWidth - gridWidth) / 2;

    let currentX = marginLeft;
    let currentY = 20;

    for (let i = 0; i < qrcodes.length; i++) {
      const qrCodeRef = qrCodeRefs.current[i];
      const qrCodeTitle = `Mesa #${i + 1}`;

      try {
        const imageDataURL = await htmlToImage.toPng(qrCodeRef);
        const qrCodeWidth = 40;
        const qrCodeHeight = 40;
        const margin = 10;

        doc.setDrawColor(0);
        doc.setLineWidth(0.5);
        doc.setLineDash([1, 1]);

        doc.line(currentX - margin, currentY - margin, currentX + qrCodeWidth + margin, currentY - margin);
        doc.line(currentX - margin, currentY + qrCodeHeight + margin, currentX + qrCodeWidth + margin, currentY + qrCodeHeight + margin);
        doc.line(currentX - margin, currentY - margin, currentX - margin, currentY + qrCodeHeight + margin);
        doc.line(currentX + qrCodeWidth + margin, currentY - margin, currentX + qrCodeWidth + margin, currentY + qrCodeHeight + margin);

        doc.addImage(imageDataURL, 'PNG', currentX, currentY, qrCodeWidth, qrCodeHeight);
        doc.text(qrCodeTitle, currentX + qrCodeWidth / 2, currentY - 5, { align: 'center' });

        doc.setLineDash([]);
      } catch (error) {
        console.error('Error generating QR code image:', error);
      }

      currentX += 60;

      if ((i + 1) % columns === 0) {
        currentX = marginLeft;
        currentY += 60;
      }

      if ((i + 1) % (columns * 4) === 0) {
        doc.addPage();
        currentX = marginLeft;
        currentY = 20;
      }
    }

    doc.save('QrMesas.pdf');
    setIsLoading(false);
  };

  const generateQRCodes = () => {
    if (quantity === null) {
      setQRCodes([]);
      return;
    }

    const codes = [];

    for (let i = 1; i <= quantity; i++) {
      const code = i.toString();
      codes.push(code);
    }

    setQRCodes(codes);
  };

  useEffect(() => {
    generateQRCodes(); // Generar los códigos QR automáticamente al cambiar la cantidad
  }, [quantity]);

  useEffect(() => {
    if (qrCodeRefs.current.length > 0) {
      qrCodeRefs.current.forEach((ref) => {
        if (ref && ref.parentElement) {
          const container = ref.parentElement;
          const containerSize = container.offsetWidth;
          const qrCodeSize = Math.min(containerSize, 90);

          container.style.width = qrCodeSize + 'px';
          container.style.height = qrCodeSize + 'px';
        }
      });
    }
  }, [qrcodes]);

  const secretKey = 'lwY6I30k3Y00413!9e8V9';

  const encrypt = (code) => {
    const encrypted = crypto.AES.encrypt(code, secretKey).toString();
    const encoded = crypto.enc.Base64.stringify(crypto.enc.Utf8.parse(encrypted));
    return encoded;
  };

  return (
    <main className='mainQR'>
      <SideBar />

      <div className='containerGenerateQR'>
        <div className='contentOfCards'>

          <div className='containerCardQR'>
            <h2 className='tittleCard'>Generador QR Mesas</h2>
            <img className='imgReference' src={iconQR || 'cargando'} alt='QR Code' />

            <form className='formQR'>
              <label className='labelFormQR'>Cantidad de códigos a generar:</label>
              <input
                type='number'
                name='quantity'
                maxLength={2}
                min='1'
                className='inputQuantityQRs'
                onChange={handleQuantityChange}
              />
              <button type='button' className='btnDownloadQR' onClick={handleDownload}>
                {isLoading ? 'Cargando...' : 'Descargar'}
              </button>
            </form>
            
          </div>
          <div className="titleQrsPreview">
            <h2>Codigos Qr</h2>
          </div>
          <div className='containerQRS'>
            {qrcodes.map((code, index) => (
              <div className='qrCodes' key={index} style={{ display: 'inline-block', margin: '10px' }}>
                <h4>Mesa #{index + 1}</h4>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    border: '1px dashed black',
                    padding: '5px',
                    boxSizing: 'border-box',
                  }}
                  
                  >
                  <QRCode
                  ref={(ref) => (qrCodeRefs.current[index] = ref)}
                    value={`https://siac-corp.pages.dev/menu/${encrypt(code)}`}
                    size={80}
                  />
                </div>
              </div>
            ))}
          </div>

         
        </div>
      </div>
    </main>
  );
};
