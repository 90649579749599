import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ProductsForUserContext } from '../ContainerTittleMenuUser/ContainerTittleMenuUser';

export const ContainerCategoriesForUser = () => {
  const {
    setActiveCategory,
    activeCategory,
    listProduct,
    setCategoryContent,
  } = useContext(ProductsForUserContext);
  const [activeLink, setActiveLink] = useState(0);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          'https://backend-render-corp.onrender.com/api/products/categories'
        );
        const updatedCategories = response.data;
        setCategories(updatedCategories);
      } catch (error) {
        console.log('Error al obtener las categorías', error);
      }
    };

    fetchCategories();
    const interval = setInterval(fetchCategories, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [activeCategory]);

  const onClickLink = (index) => {
    setActiveLink(index);
  };

  const filterProductsByCategory = (category, index) => {
    setActiveLink(index);
    setActiveCategory(category);

    if (category.toLowerCase() === 'entrada') {
      setCategoryContent(true);
      listProduct.forEach((producto) => {
        // console.log(producto.name);
      });
    } else {
      const filteredProducts = listProduct.filter((product) => {
        return (
          product.category &&
          product.category.name.toLowerCase() === category.toLowerCase()
        );
      });

      setCategoryContent(filteredProducts.length > 0);
    }
  };



  return (
    <div className="containerCategories">
      {categories.map((category, index) => (
        <div
          onClick={() => filterProductsByCategory(category.name, index)}
          key={index}
          className={ activeLink === index ? 'active' : 'containerLinks' }
        >
          <Link
            onClick={() => onClickLink(index)}
            className={ activeLink === index ? 'activeLink' : 'categories' }
          >
            {category.name === 'All' ? 'Todos' : category.name}
          </Link>
        </div>
      ))}
    </div>
  );
};
