import React from 'react';
import { createContext, useState } from 'react';
import { ContainerCategoriesForUser } from '../ContainerCategoriesForUser/ContainerCategoriesForUser';
import { ContainerTittleMenuUser } from '../ContainerTittleMenuUser/ContainerTittleMenuUser';

export const ContainerProductsUser = () => {
 
  return (
    <div className="containerProductsUser">
      <ContainerTittleMenuUser />

    </div>
  )
}
