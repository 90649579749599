
import React from 'react';
// LAYOUTS
import { MainHome } from '../../layouts/MainHome/MainHome'

export const Home = () => {
  return (
     <MainHome />
  )
}
