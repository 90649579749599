import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import { InputUI } from '../../UI/InputUI/InputUI';
import { ButtonUI } from '../../UI/ButtonUI/ButtonUI';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { createProducts } from '../../features/products/productSlice';
import { useSelector, useDispatch } from 'react-redux';

export const FormCreateProducts = ({ resetForm, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    category: '',
    image: '',
  });

  const [isUploading, setIsUploading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { name, price } = formData;

  const { isError, isSuccess, message } = useSelector((state) => state.auth);

  useEffect(() => {
    loadCategoryOptions();
  }, [onClose]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError, isSuccess, message]);

  const loadCategoryOptions = async () => {
    try {
      const response = await axios.get('https://backend-render-corp.onrender.com/api/products/categories');
      const formattedOptions = response.data.map((category) => ({
        value: category.name,
        label: category.name,
      }));

      setCategoryOptions(formattedOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadImage = async (e) => {
    try {
      const { files } = e.target;
      if (files.length > 0) {
        setIsUploading(true);

        const data = new FormData();
        data.append('file', files[0]);

        const res = await axios.post(`https://backend-render-corp.onrender.com/api/users/upload`, data);
        console.log(res.data);
        // Verifica si la respuesta contiene la URL de la imagen

        if (res.data.imageUrl) {
          const imageUrl = res.data.imageUrl;
          setFormData((prevState) => ({
            ...prevState,
            image: imageUrl,
          }));
          setIsUploading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsUploading(false);
    }





  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!name || !price || !selectedCategory) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe llenar todos los campos antes de enviar el formulario',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    if (formData.image !== '') {
      dispatch(createProducts(formData));

      Swal.fire({
        icon: 'success',
        title: 'Éxito',
        text: 'Enviado con éxito',
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        setFormData({
          name: '',
          price: '',
          category: '',
          image: '',
        });
        navigate('/products');
        onClose();
      });
    } else {
      console.log('La imagen aún se está cargando');
    }
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setFormData((prevState) => ({
      ...prevState,
      category: selectedOption ? selectedOption.value : '',
    }));
  };


  return (
    <form className='formCreateProduct' onSubmit={onSubmit} >
      <h1>Crear Producto</h1>
      <InputUI
        typeInpt='text'
        style='inputProduct'
        textInpt='Ingrese Nombre'
        idInpt='name'
        nameInpt='name'
        valueInpt={name}
        eventInpt={onChange}
      />

      <InputUI
        typeInpt='number'
        style='inputProduct'
        textInpt='Ingrese Precio'
        idInpt='price'
        nameInpt='price'
        valueInpt={price}
        eventInpt={onChange}
      />

      <div className="inputProducts">
        <label className="inputProductLabel" htmlFor="inputProducts">
          Seleccione Categoría
        </label>
        <div className="inputProductSelect">
          <Select
            options={categoryOptions}
            value={selectedCategory}
            placeholder="Categorías"
            onChange={handleCategoryChange}
            classNamePrefix="custom-select"
            className="custom-select"
          />
        </div>
      </div>


      <div className='containerInputFile'>
        <p>Agregar Imagen</p>
        <InputUI
          style='btnUploadImage'
          typeInpt='file'
          textInpt='Inserte Imagen'
          eventInpt={uploadImage}
        />
      </div>


      {isUploading ? (
        <div className='loader'></div>
      ) : (
        <ButtonUI typeBtn='submit' style='btnCreateProduct' text='Crear producto' />
      )}
    </form>
  );
};
