import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import React, { useEffect } from 'react';
// Layout
import Login from "./components/pages/Login/Login";
import Register from "./components/pages/Register/Register";
import { Home } from "./components/pages/Home/Home";
import { ForgotPassword } from "./components/pages/ForgotPassword/ForgotPassword";
import { Profile } from "./components/pages/Profile/Profile";
import { ResetPassword } from "./components/pages/ResetPassword/ResetPassword";
import { Products } from "./components/pages/Products/Products";
import { Notifications } from "./components/pages/Notifications/Notifications";
import { Users } from "./components/pages/Users/Users";
import { UpdateProfile } from "./components/pages/UpdateProfile/UpdateProfile";
import { ProductsUser } from "./components/pages/ProductsUser/ProductsUser"
import { ShoppingCart } from "./components/pages/ShoppingCart/ShoppingCart";
import { GeneraeteQR } from "./components/pages/GenerateQR/GeneraeteQR";


import axios from "axios";

function App() {
  useEffect(() => {
    window.document.title = 'SIAC';

    const checkToken = () => {
      const token = localStorage.getItem('user');

      // Obtener la ruta actual
      const currentPath = window.location.pathname;

      // Verificar si el token existe y no es null
      if (token && token !== 'null') {
        const formattedToken = token.replace(/^"(.*)"$/, '$1');
        // Agregar el token a los encabezados de las solicitudes
        axios.defaults.headers.common['Authorization'] = `Bearer ${formattedToken}`;
      } 
    };

    // Verificar y redireccionar cada 5 segundos
    const interval = setInterval(checkToken, 2000);

    // Limpiar el intervalo al desmontar el componente
    return () => {
      clearInterval(interval);
    };
  }, []);
  
  return (
    <>
      <Router>
        <div className="container">

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/profile" element={<Profile />} />

            <Route path="/update-profile" element={<UpdateProfile />} />

            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/products" element={<Products />} />
            <Route path="/menu/:mesaId" element={<ProductsUser />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/users" element={<Users />} />
            <Route path="/shopping-cart" element={<ShoppingCart />} />

            <Route path="/generate-qr" element={<GeneraeteQR />} />


          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </>
  )
}


export default App;
