import React, { useEffect, useState } from "react";
import axios from "axios";
// UI
import { ImgUI } from "../../UI/ImgUI/ImgUI";
// IMG
import imageBanner from "../../../Images/iconUser.png";

export const BannerProfile = () => {
  // const localHost = "https://backend-render-corp.onrender.com";
  const [datUser, setUser] = useState({});

  useEffect(() => {
    const getInfoUser = async () => {
      try {
        const res = await axios.get(`https://backend-render-corp.onrender.com/api/users/me`);
        setUser(res.data);
        // console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    getInfoUser();

    const interval = setInterval(() => {
      getInfoUser();
    }, 5000); // Actualizar la información cada 5 segundos

    return () => {
      clearInterval(interval);
    };
  }, []);

  let formattedName =
    datUser.name && datUser.name.charAt(0).toUpperCase() + datUser.name.slice(1);
  let formattedRole =
    datUser.roles && datUser.roles.charAt(0).toUpperCase() + datUser.roles.slice(1);
    if (datUser.roles === "mesero") {
      formattedRole = "Meser@";
    }
    
  return (
    <div className="bannerProfile">
      <div className="contentUserBanner">

        <div className="containerImgProfile">
          <ImgUI style="imgProfile" routeImg={datUser.image || imageBanner} />
        </div>

        <p className="nameProfile">{formattedName}</p>
        <p className="roleProfile">{formattedRole}</p>
      </div>
    </div>
  );
};
