import React, { useState, useEffect } from 'react';
import personImg from '../../../Images/personIcon.png';
import { ImgUI } from '../../UI/ImgUI/ImgUI';
import axios from 'axios';
import io from 'socket.io-client';
import Swal from 'sweetalert2';
import { RadioButtonGroup } from '../RadioButtonGroup/RadioButtonGroup';

const socket = io('https://backend-render-corp.onrender.com');

export const ContentUsers = () => {
  const [expandedCard, setExpandedCard] = useState(null);
  const [listUsers, setUsers] = useState([]);

  useEffect(() => {
    const getInfoUser = async () => {
      try {
        const res = await axios.get(`https://backend-render-corp.onrender.com/api/users/all`);
        setUsers(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    getInfoUser();

    return () => {
      socket.disconnect();
    };
  }, [listUsers]);

  const handleShowDetails = (dni) => {
    setExpandedCard((prevExpandedCard) => (prevExpandedCard === dni ? null : dni));
  };

  const handleFlipToFront = () => {
    setExpandedCard(null);
  };

  const handleOptionChange = async (iD, selectedValue) => {
    const userData = { userId: iD, roles: selectedValue };

    try {
      const res = await axios.put('https://backend-render-corp.onrender.com/api/users/update-role', userData);
      console.log(res);
      Swal.fire({
        icon: 'success',
        title: 'Rol actualizado',
        toast: true,
        position: 'top-right',
        width: '450px',
        timer: 4000, // 4 segundos
        timerProgressBar: true,
        text: 'El rol se ha actualizado correctamente.',
      });
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        toast: true,
        position: 'top-right',
        timer: 4000, // 4 segundos
        timerProgressBar: true,
        text: 'Ocurrió un error al actualizar el rol del usuario.',
      });
    }
  };

  const deleteUser = async (id) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmación',
      text: '¿Estás seguro de que deseas eliminar este usuario?',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await axios.delete(`https://backend-render-corp.onrender.com/api/users/deleteUser/${id}`);
          console.log(res);
          Swal.fire({
            icon: 'success',
            toast: true,
            position: 'top-right',
            title: 'Usuario eliminado',
            text: 'El usuario ha sido eliminado correctamente.',
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: 'error',
            toast: true,
            position: 'top-right',
            title: 'Error',
            text: 'Ocurrió un error al eliminar el usuario.',
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          icon: 'info',
          toast: true,
          position: 'top-right',
          title: 'Operación cancelada',
          text: 'NO se elimino el usuario.',
        });
      }
    });
  };
  

  return (
    <div className='contentUsers'>
      <div className='containerCardsUsers'>
        {listUsers.map((usuario) => (
          <div className={`cardUser ${expandedCard === usuario.dni ? 'flipped' : ''}`} key={usuario.dni}>
            <div className='cardFront'>
              <div className='cardFrontContent'>
                <img src={usuario.image || personImg} alt='Avatar' className='cardFrontImage' />
                <div className='cardFrontText'>
                  <p>Nombre: {usuario.name}</p>
                  <p>Rol: {usuario.roles.name}</p>
                </div>
              </div>
              <div className="containerUsersBtn">
                <button className='showDetailsButton' onClick={() => handleShowDetails(usuario.dni)}>
                  Más información
                </button>
                <button className='btnDeleteUser' onClick={() => deleteUser(usuario._id)}>X</button>
              </div>
            </div>
            {expandedCard === usuario.dni && (
              <div className='cardBack'>
                <p>DNI: {usuario.dni}</p>
                <p>Email: {usuario.email}</p>
                <p>Teléfono: {usuario.phone}</p>
                <p>Dirección: {usuario.address}</p>
                <RadioButtonGroup groupId={usuario._id} onOptionChange={handleOptionChange} />
                <button className='flipToFrontButton' onClick={handleFlipToFront}>
                  Regresar
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
