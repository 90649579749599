import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/auth/authSlice';
import axios from 'axios';
import io from 'socket.io-client';

// UI
import { ImgUI } from '../../UI/ImgUI/ImgUI';

// IMG
import logoWhite from '../../../Images/whiteIcon.png';
import iconNotifications from '../../../Images/iconNotifications.png';
import iconMenu from '../../../Images/iconProducts.png';
import iconProfile from '../../../Images/iconProfile.png';
import iconLogout from '../../../Images/iconLogout.png';
import iconUsers from '../../../Images/iconUsers.png';
import iconQR from '../../../Images/iconQR.png';


const socket = io('https://backend-render-corp.onrender.com');
// const socket = io('https://backend-render-corp.onrender.com');

export const SideBar = () => {
  const dispatch = useDispatch();
  const [rol, setRol] = useState('');

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const res = await axios.get('https://backend-render-corp.onrender.com/api/users/me');
        const { roles } = res.data;
        // console.log(roles);
        setRol(roles);
      } catch (err) {
        console.log(err);
      }
    };

    fetchRole();
  

    const interval = setInterval(() => {
      fetchRole();
    }, 1000); // Actualizar la información cada 5 segundos

    


    socket.on('roles', (data) => {
      setRol(data.roles);
    });

    return () => {
      socket.off('roles');
      clearInterval(interval);
    };

    // return () => {
  }, []);

  const onSubmit = () => {
    dispatch(logout());
  };

  return (
    <div className="sideBar">
      <nav className="navBarInSideBar">
        {rol === 'admin' && (
          <ul>
            <li className="listLinks">
              <ImgUI style="imgIcon" routeImg={logoWhite} />
            </li>
            <li className="listLinks">
              <Link className="links" to="/profile">
                <ImgUI style="iconProfile" routeImg={iconProfile}></ImgUI>
              </Link>
            </li>
            <li className="listLinks">
              <Link className="links" to="/products">
                <ImgUI style="iconCalendary" routeImg={iconMenu}></ImgUI>
              </Link>
            </li>
            <li className="listLinks">
              <Link className="links" to="/notifications">
                <ImgUI
                  style="iconNotification"
                  routeImg={iconNotifications}
                ></ImgUI>
              </Link>
            </li>
            <li className="listLinks">
              <Link className="links" to="/users">
                <ImgUI style="iconStats" routeImg={iconUsers}></ImgUI>
              </Link>
            </li>
            <li className="listLinks">
              <Link className="links" to="/generate-qr">
                <ImgUI style="iconQR" routeImg={iconQR}></ImgUI>
              </Link>
            </li>
          </ul>
        )}

        {rol === 'Jefe Cocina' && (
          <ul>
            <li className="listLinks">
              <ImgUI style="imgIcon" routeImg={logoWhite} />
            </li>
            <li className="listLinks">
              <Link className="links" to="/products-user">
                <ImgUI style="iconProfile" routeImg={iconProfile}></ImgUI>
              </Link>
            </li>
            <li className="listLinks">
              <Link className="links" to="/notifications">
                <ImgUI
                  style="iconNotification"
                  routeImg={iconNotifications}
                ></ImgUI>
              </Link>
            </li>
            <li className="listLinks">
              <Link className="links" to="/products">
                <ImgUI style="iconCalendary" routeImg={iconMenu}></ImgUI>
              </Link>
            </li>
          </ul>
        )}

        {rol === 'mesero' && (
          <ul>
            <li className="listLinks">
              <ImgUI style="imgIcon" routeImg={logoWhite} />
            </li>
            <li className="listLinks">
              <Link className="links" to="/profile">
                <ImgUI style="iconProfile" routeImg={iconProfile}></ImgUI>
              </Link>
            </li>
            <li className="listLinks">
              <Link className="links" to="/notifications">
                <ImgUI
                  style="iconNotification"
                  routeImg={iconNotifications}
                ></ImgUI>
              </Link>
            </li>
          </ul>
        )}

        <ul>
          <li className="listLinks">
            <Link onClick={onSubmit} className="toHome" to="/">
              <ImgUI style="iconLogout" routeImg={iconLogout}></ImgUI>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};
