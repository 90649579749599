import React from 'react'

export const ContainerTittleMenu = () => {
  return (
    <>
        <div className="tittleMenu">
            <h1 className='tittle'>Productos</h1>
        </div>

        <h1 className='tittleCategories'>Categorías</h1>
    </>
  )
}
