import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { ImgUI } from '../../UI/ImgUI/ImgUI';
import { ButtonUI } from '../../UI/ButtonUI/ButtonUI';
import { ProductsForUserContext } from '../ContainerTittleMenuUser/ContainerTittleMenuUser';
import Swal from 'sweetalert2';

const socket = io('https://backend-render-corp.onrender.com');

export const ProductsUser = () => {
  const {
    setCategoryContent,
    activeCategory,
    categoryContent,
    listProduct,
    setListProduct,
    listProductCart,
    setListProductCart
  } = useContext(ProductsForUserContext);

  const [totalPrice, setTotalPrice] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const addToCart = (product) => {
    if (listProductCart.find((item) => item._id === product._id)) {
      const updatedCart = listProductCart.map((item) =>
        item._id === product._id ? { ...item, quantity: item.quantity + 1 } : item
      );
      setTotalPrice(totalPrice + product.price);
      setListProductCart(updatedCart);
    } else {
      const newCartItem = { ...product, quantity: 1 };
      setTotalPrice(totalPrice + product.price);
      setListProductCart([...listProductCart, newCartItem]);
    }
    Swal.fire({
      icon: 'success',
      title: 'Mensaje',
      text: 'Producto agregado al carrito',
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      timer: 1500
    });
  };

  const fetchProductsList = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));

      const res = await axios.get('https://backend-render-corp.onrender.com/api/products/all');
      setListProduct(res.data);

      const productsInActiveCategory = res.data.filter(
        (producto) => activeCategory === 'Entrada' || producto.category.name.toLowerCase() === activeCategory.toLowerCase()
      );

      setCategoryContent(productsInActiveCategory.length > 0);

      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoadingProducts(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const filterProductsByCategory = () => {
      const filteredProducts = activeCategory !== 'All'
        ? listProduct.filter((producto) => producto.category.name.toLowerCase() === activeCategory.toLowerCase())
        : listProduct;
      setFilteredProducts(filteredProducts);
    };

    fetchProductsList();
    filterProductsByCategory();

    return () => {
      socket.disconnect();
    };
  }, [activeCategory, listProduct]);

  useEffect(() => {
    socket.connect();

    socket.on('productos', (listProduct) => {
      setListProduct(listProduct);
      setCategoryContent(listProduct.length > 0);
    });

    socket.on('nuevoProducto', (producto) => {
      setListProduct((prevListProduct) => [...prevListProduct, producto]);
      if (producto.category.name.toLowerCase() === activeCategory.toLowerCase()) {
        setCategoryContent(true);
      }
    });
  }, [activeCategory]);

  useEffect(() => {
    const updateCart = () => {
      const updatedCart = listProductCart.map((item) => {
        const product = listProduct.find((p) => p._id === item._id);
        if (product) {
          return { ...item, price: product.price };
        }
        return item;
      });
      setListProductCart(updatedCart);
    };

    updateCart();
  }, [listProduct]);

  return (
    <>
      <div className="containerCardsMenu">
        {loadingProducts ? (
          <p className="emptyProducts">Cargando productos...</p>
        ) : filteredProducts.length !== 0 ? (
          filteredProducts.map((producto) => (
            <div key={producto._id} className="cardOrderUser">
              <div className="containerImgOrderUser">
                <ImgUI style="imgOrderUser" routeImg={producto.image} />
              </div>
              <div className="infoOrderUsers">
                <label className="nameOrderUserLabel">Nombre</label>
                <p className="nameOrderUser">{producto.name.charAt(0).toUpperCase() + producto.name.slice(1)}</p>

                <label className="categoryOrderUserLabel">Categoría</label>
                <p className="categoryOrderUser">{producto.category ? producto.category.name : 'Sin categoría'}</p>
              </div>
              <div className="containerButtons">
                <p className="priceOrderUser">${producto.price}</p>
                <ButtonUI onClicks={() => addToCart(producto)} style="btnAddToCar" text="  ✚" />
              </div>
            </div>
          ))
        ) : (
          <p className="emptyProducts">No hay productos en la categoría actual.</p>
        )}
      </div>
    </>
  );
};
