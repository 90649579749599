import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import axios from 'axios';

import { ContentNotifyOrder } from './ContentNotifyOrder/ContentNotifyOrder';
import moment from 'moment';
import Swal from 'sweetalert2';

const socket = io('https://backend-render-corp.onrender.com');
export const NotifyOrderContext = React.createContext(null);

export const ContentNotifications = () => {
  const [productCancel, setProductCancel] = useState(false);
  const [listProduct, setProduct] = useState([]);
  const [showContentNotifyOrder, setShowContentNotifyOrder] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [rol, setRol] = useState('');
  const [rolName, setRolName] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const res = await axios.get('https://backend-render-corp.onrender.com/api/users/me');
        const { roles,name } = res.data;
        ;
        setRol(roles);
        setRolName(name)
        setDataLoaded(true);
      } catch (err) {
        console.log(err);
      }
    };

    fetchRole();
  

    const interval = setInterval(() => {
      fetchRole();
    }, 1000); // Actualizar la información cada 5 segundos

    


    socket.on('roles', (data) => {
      setRol(data.roles);
    });

    return () => {
      socket.off('roles');
      clearInterval(interval);
    };

    // return () => {
  }, []);
  useEffect(() => {
    // console.log(listProduct);
    socket.on('productos', (data) => {
      setProduct(data.products);
    });

    socket.on('nuevoProducto', (producto) => {
      setProduct((prevList) => [...prevList, producto]);
    });

    return () => {
      socket.off('productos');
      socket.off('nuevoProducto');
    };
  }, []);

  useEffect(() => {
    const getProductsList = async () => {
      // console.log(rol + "get");
      try {
        const res = await axios.get('https://backend-render-corp.onrender.com/api/products/orders');
        setProduct(res.data);
        // console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    getProductsList();
  }, [listProduct]);

  useEffect(() => {
    if (productCancel !== null && selectedProduct) {
      deleteOrder(selectedProduct);
      
    }
  }, [productCancel]);

  const prepareOrder = async (product) => {
    if (!product) {
      return;
    }
    try {
      // Actualizar el estado en función del estado actual
      if (product.orderStatus === 'Pendiente') {
        product.orderStatus = 'Preparando';
      } else if (product.orderStatus === 'Preparando') {
        product.orderStatus = 'Entregar';
      }
  

      const dataOrder={
        orderId: product._id,
          orderStatus: product.orderStatus
      }
        const response = await axios.put('https://backend-render-corp.onrender.com/api/products/updateOrders',dataOrder)
      
      if (response.data) {
        Swal.fire({
          title: 'Estado de la orden cambio ',
          text: response.data.orderStatus,
          toast: true,
          position: 'top-right',
          showConfirmButton: false,
          timer: 1500,
          icon: 'success',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Estado Orden',
        text: "Error al preparar la orden",
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 1500,
        icon: 'error',
      });
      // console.log('Error al preparar la orden');
    }
  };
  const takeOrder = async (product,nameRol) => {
    if (!product || !nameRol) {
      return;
    }
    try {
 
      const dataOrder={
        orderId: product._id,
          mesero: rolName
      }
        const response = await axios.put('https://backend-render-corp.onrender.com/api/products/updateOrders',dataOrder)
      
      if (response.data) {
        Swal.fire({
          title: 'Se tomo la orden',
          text: `Orden tomada por: ${rolName}`,
          toast: true,
          position: 'top-right',
          showConfirmButton: false,
          timer: 1500,
          icon: 'success',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Tomar orden',
        text: "Error al asignar la orden",
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 1500,
        icon: 'error',
      });
      // console.log('Error al preparar la orden');
    }
  };

  const deleteOrder = async (product) => {
    // setProductCancel(true)
    // console.log(product);
    try {
      // Mostrar mensaje de confirmación
      const result = await Swal.fire({
        title: 'Eliminar Orden',
        text: '¿Estás seguro de cancelar esta orden?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, cancelar',
        cancelButtonText: 'Cancelar',
      });
      const dataOrder={
        orderId: product._id,
      }
      // Si el usuario confirma la eliminación
      if (result.isConfirmed) {
        product.orderStatus = 'cancelado';
         await prepareOrder(product);
         setTimeout(async () => {
          const response = await axios.delete('https://backend-render-corp.onrender.com/api/products/deleteOrders', dataOrder);
  
          if (response.data) {
            Swal.fire({
              title: 'Se eliminó la orden',
              text: response.data.orderStatus,
              toast: true,
              position: 'top-right',
              showConfirmButton: false,
              timer: 1500,
              icon: 'success',
            });
          }
        }, 1000); // Esperar 3 segundos antes de eliminar la orden
      }
    } catch (error) {
      Swal.fire({
        title: 'Estado Orden',
        text: 'Error al eliminar la orden',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 1500,
        icon: 'error',
      });
    }
  };
  

  const handleClose = () => {
    setShowContentNotifyOrder(false);
  };

  const notificationSelected = (product) => {
    setSelectedProduct(product);
    setShowContentNotifyOrder(true);
  };
  
  const getTimeAgo = (orderDate) => {
    const duration = moment.duration(moment().diff(moment(orderDate)));
    const hours = Math.floor(duration.asHours());
    let minutes = Math.floor(duration.asMinutes()) % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`
    }
    return `${hours}:${minutes} minutos`;
  };
  const sortedListProduct = [...listProduct]
  .filter(producto => {
    if (rol === 'mesero') {
      // Filtrar solo si el rol es mesero y el estado de orden es "Entregar"
      return producto.orderStatus === 'Entregar';
    } else {
      // Incluir todos los productos en caso contrario
      return true;
    }
  })
  .sort((a, b) => {
    if (!a.mesero && b.mesero) {
      return -1; // a sin mesero, b con mesero, colocar a antes de b
    } else if (a.mesero && !b.mesero) {
      return 1; // a con mesero, b sin mesero, colocar b antes de a
    } else {
      // Ambos tienen o no tienen mesero, ordenar por fecha
      const dateA = moment(a.orderDate);
      const dateB = moment(b.orderDate);
      return dateB - dateA;
    }
  });

  return (
    <div className="contentNotifications">
      <div className="containerNotifications">
        {dataLoaded ? (
          sortedListProduct.length > 0 ? (
            <>
              {sortedListProduct
                .filter((producto) => producto.orderStatus !== 'cancelado')
                .map((producto, index) => (
                  <div key={producto._id} className="contentCardNotify">
                    <div className="contentTitleNotify">
                      <h2 className="titleNotify">
                        Orden Mesa: <span className="textNotify">#{producto.mesa}</span>
                      </h2>
                      {producto.mesero ? (
                        <p className="titleNotify">
                          Tomada Por: <span className="textNotify">{producto.mesero}</span>
                        </p>
                      ) : (
                        <p className="titleNotify">
                          Hora: <span className="textNotify">{getTimeAgo(producto.orderDate)}</span>
                        </p>
                      )}
                    </div>
  
                    <div className="containerBtnNotify">
                      {rol === 'mesero' ? (
                        <div className="btnMesero">
                          <button
                            className="btnNotifyShow"
                            onClick={() => notificationSelected(producto)}
                          >
                            Ver
                          </button>
  
                          <button
                            className="btnNotifyTake"
                            onClick={() => takeOrder(producto, rol)}
                          >
                            Tomar Orden
                          </button>
                        </div>
                      ) : (
                        <>
                          <button
                            className="btnNotifyShow"
                            onClick={() => notificationSelected(producto)}
                          >
                            Ver
                          </button>
                          <button
                            className="btnNotifyGet"
                            onClick={() => prepareOrder(producto)}
                          >
                            {producto.orderStatus}
                          </button>
                          <button
                            className="btnNotifyCancel"
                            onClick={() => deleteOrder(producto)}
                          >
                            Cancelar
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ))}
  
              {sortedListProduct.every((producto) => producto.orderStatus === 'cancelado') && (
                <p className="noProductsMessage">No hay órdenes.</p>
              )}
            </>
          ) : (
            <p className="noProductsMessage">No hay órdenes para entregar disponibles...</p>
          )
        ) : (
          <p className="loaderNotify">Cargando...</p>
        )}
      </div>
  
      {showContentNotifyOrder && (
        <NotifyOrderContext.Provider value={handleClose}>
          <ContentNotifyOrder producto={selectedProduct} />
        </NotifyOrderContext.Provider>
      )}
    </div>
  );
      }
  