import React from 'react'
import { ContainerProductsUser } from '../ContainerProductsUser/ContainerProductsUser'

export const MainProductsUser = () => {
  return (
    <main className='mainProductsUser'>
      <ContainerProductsUser />
    </main>
  )
}
