
import React from 'react';
import { MainForgotPassword } from "../../layouts/MainForgotPassword/MainForgotPassword"


export const ForgotPassword = () => {
  return (
    <MainForgotPassword />
  )
}
