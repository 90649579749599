import React, { useState, createContext, useEffect, useRef } from 'react';
import { ImgUI } from '../../UI/ImgUI/ImgUI';
import iconToCar from '../../../Images/iconCar.png';
import { ContainerCategoriesForUser } from '../ContainerCategoriesForUser/ContainerCategoriesForUser';
import { ProductsUser } from '../ProductsForUser/ProductsForUser';
import iconDeleteToCar from "../../../Images/deleteToCar.png";
import iconTypeOfPay from "../../../Images/typeOfPay.svg";
import { ButtonUI } from '../../UI/ButtonUI/ButtonUI';
import { CounterCart } from "../CounterCart/CounterCart";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';


export const ProductsForUserContext = createContext();

export const ContainerTittleMenuUser = () => {
  const [activeCategory, setActiveCategory] = useState('All');
  const [listProduct, setListProduct] = useState([]);
  const [listProductCart, setListProductCart] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [categoryContent, setCategoryContent] = useState([]);
  const [orders, setOrders] = useState([]);
  const [idOrderCart, setIdOrder] = useState(0);
  const [cartId, setCartId] = useState('');
	const [orderStatus, setOrderStatus] = useState('');
	const [idMesa, setIdMesa] = useState('');
	const [orderMesero, setOrderMesero] = useState('');
	const prevOrderStatusRef = useRef('');
	const prevOrderMeseroRef = useRef('');
  
  const [namePaymentMethod, setPaymentMethod] = useState(''); // Nuevo estado para el método de pago
  // Referencia para mantener el estado anterior de la orden
  const onDeleteProduct = (producto) => {
    
      const updatedCart = listProductCart.filter(item => item._id !== producto._id);
      setListProductCart(updatedCart);
      setTotalPrice(prevTotalPrice => prevTotalPrice - (producto.price * producto.quantity));
    };

    const { mesaId } = useParams();

    useEffect(() => {
      const sendRequest = async () => {
        try {
          const response = await axios.get(`https://backend-render-corp.onrender.com/api/products/menu/${mesaId}`);
          console.log(response.data);
          setIdMesa(response.data)
          // Realiza las operaciones necesarias con la respuesta de la API
        } catch (error) {
          console.error(error);
          // Manejo de errores
        }
      };
      sendRequest();
    }, [mesaId]);


    const handleChangePaymentMethod = (event) => {
      setPaymentMethod(event.target.value);
      console.log(event.target.value);
    };


useEffect(() => {
	const getProductsList = async () => {
		try {
			const res = await axios.get('https://backend-render-corp.onrender.com/api/products/orders');
			setOrders(res.data);
      // console.log(res.data);
		} catch (err) {
			console.log(err);
		}
	};

	// Obtener los pedidos inicialmente
	getProductsList();

	// Actualizar los pedidos cada 5 segundos (ajusta el intervalo según tus necesidades)
	const interval = setInterval(() => {
		getProductsList();
	}, 5000);

	// Limpia el intervalo cuando el componente se desmonte
	return () => clearInterval(interval);
}, []);

const showOrderStatusSwal = (status, title, message) => {
  Swal.fire({
    icon: status === 'cancelado' ? 'error' : 'success',
    title: title,
    html: message,
    showConfirmButton: true,
  });
};

useEffect(() => {
  // console.log(orders);
  // Verificar si la orden específica se ha actualizado
  const foundOrder = orders.find(order => order.cartId === Number(idOrderCart));

  if (foundOrder) {
    const currentOrderStatus = foundOrder.orderStatus;
    const prevOrderStatus = prevOrderStatusRef.current;
    const currentOrderMesero = foundOrder.mesero;
    const prevOrderMesero = prevOrderMeseroRef.current;

    if (currentOrderStatus !== prevOrderStatus || currentOrderMesero !== prevOrderMesero) {
      setOrderStatus(currentOrderStatus);
      setOrderMesero(currentOrderMesero)

      prevOrderStatusRef.current = currentOrderStatus;
      prevOrderMeseroRef.current = currentOrderMesero;

      let message = '';

      if (currentOrderStatus === 'cancelado') {
        message = 'Se ha cancelado su orden, realice una nueva o contacte a un mesero';
      } else {
        message = `El pedido se ha enviado a cocina<br>Estado: <b>${currentOrderStatus}...</b> 
        <br>Entrega Mesero: <b>${currentOrderMesero || "Esperando Entrega"}...</b>`;
    
        // if (currentOrderMesero != "") {
        //   message += `<br>Entrega Mesero: <b>${currentOrderMesero}...</b>`;
        // }
      }

      const title = currentOrderStatus === 'cancelado'
        ? 'Orden Cancelada'
        : 'Seguimiento de su orden';

      showOrderStatusSwal(currentOrderStatus, title, message);
    }

   
  } else if (prevOrderStatusRef.current !== 'cancelado' && foundOrder === undefined) {
    setOrderStatus('cancelado');
    prevOrderStatusRef.current = 'cancelado';
    setOrderMesero('')
    // showOrderStatusSwal('cancelado', '', 'Orden Cancelada', 'Se ha cancelado su orden, realice una nueva o contacte a un mesero');
  }
}, [idOrderCart, orders]);


  useEffect(() => {

    setListProductCart(prevListProductCart => {
      const updatedCart = prevListProductCart.filter(item => {
        const productExists = listProduct.some(product => product._id === item._id);
        return productExists;
      });

      return updatedCart;
    });
  }, [listProduct]);

  const onUpdateProductQuantity = (productId, newQuantity) => {
    const updatedCart = listProductCart.map(item => {
      if (item._id === productId) {
        return {
          ...item,
          quantity: newQuantity
        };
      }
      return item;
    });
    setListProductCart(updatedCart);
    updateTotalPrice(updatedCart);
  };

  const updateTotalPrice = (cart) => {
    const totalPrice = cart.reduce((acc, item) => {
      return acc + (item.price * item.quantity);
    }, 0);
    setTotalPrice(totalPrice);
  };

  useEffect(() => {
    const fetchProductsList = async () => {
      try {
        const res = await axios.get('https://backend-render-corp.onrender.com/api/products/all');
        setListProduct(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProductsList();
  }, []);

	
  useEffect(() => {
    const updateCart = () => {
      const updatedCart = listProductCart.map((item) => {
        const product = listProduct.find((p) => p._id === item._id);
        if (product) {
          return { ...item, price: product.price, name: product.name };
        }
        return item;
      });

      setListProductCart(updatedCart);
    };

    if (listProduct.length > 0) {
      updateCart();
    }
  }, [listProduct]);

  useEffect(() => {
    updateTotalPrice(listProductCart);
  }, [listProductCart]);

  const handleCartClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  // ...
  
  const sendCartToServer = async () => {
    try {
      const cartItemsToSend = listProductCart.map((item) => {
        if (!item._id) {
          throw new Error('Los elementos del carrito deben tener productId');
        }
        return { productId: item._id, name: item.name, image:item.image , quantity: item.quantity, paymentMethod: item.paymentMethod };
      });
      
      const response = await axios.post('https://backend-render-corp.onrender.com/api/products/shopUser', {
        cartId: cartId, // Enviar el cartId actual al servidor
        items: cartItemsToSend,
        totalAmount: totalPrice,
        mesa: idMesa,
        paymentMethod: namePaymentMethod
      });
      console.log( {paymentMethod: namePaymentMethod});

      // console.log(response.data)
			let data = response.data.cartId;
      setIdOrder(data)
			console.log(idOrderCart);
      console.log(response.data.cartId + "´pedido");
			console.log(data + "orden");
      
      // setIdOrder(idOrders);
      // Obtener el nuevo cartId del servidor
      const newCartId = response.data.cartId;
      // getProductsList()
  
      closeModal();
      // Agregar la orden al estado de las órdenes
      setOrders((prevOrders) => [...prevOrders, response.data]);
  
      // Vaciar el carrito
      setListProductCart([]);
      setTotalPrice(0);
  
      // Actualizar el cartId actual
      setCartId(newCartId);

    } catch (error) {
      console.error('Error al enviar el carrito:', error);
    }
  };

  return (
    <div className="containerProductsUser">
      <div className='containerTittle'>
        <div className="tittleMenuUser">
          <h1 className='tittleUser'>Menú</h1>
        </div>

        <div className='containerIconToCart' onClick={handleCartClick}>
          <ImgUI style='iconToCar' routeImg={iconToCar} />
        </div>
      </div>

      <ProductsForUserContext.Provider
        value={{
          activeCategory,
          setActiveCategory,
          listProduct,
          setListProduct,
          listProductCart,
          setListProductCart,
          categoryContent,
          setCategoryContent,
          onUpdateProductQuantity
        }}
      >
        <ContainerCategoriesForUser />
        <ProductsUser />
      </ProductsForUserContext.Provider>

      {showModal && (
        <div className="modal">
          <ButtonUI style="closeModal" onClicks={closeModal} text='⨯' />

          <main className='mainShoppingCart'>
            <div className='contentShoppingCart'>
              <div className="containerTittleCart">
                
                <h2 className="titleShoppingCart">{`Su Orden Mesa #${idMesa}`}</h2>
                <hr className="line" />
              </div>

              <div className='containerProductsShoppingCart'>
                {listProductCart.length ? (
                  <>
                    {listProductCart.map((producto) => (
                      <div key={producto._id} className='containerProductCart'>
                        <div className="containerImageProduct">
                          <ImgUI style="img" routeImg={producto.image} />
                        </div>

                        <div className="infoProduct">
                          <p className="productName">{producto.name}</p>

                          <CounterCart
                            productId={producto._id}
                            quantity={producto.quantity}
                            onUpdateQuantity={onUpdateProductQuantity}
                          />
                        </div>

                        <div className="containerButtonCart">
                          <ButtonUI
                            style="btnDeleteCart"
                            onClicks={() => onDeleteProduct(producto)}
                            text={<ImgUI style="deleteToCar" routeImg={iconDeleteToCar}></ImgUI>}
                          />
                          <p className="priceProduct">${producto.price}</p>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <p className='cart-emptyCart'>El carrito está vacío</p>
                )}
              </div>

              <div className="containerLine">
                <hr className="line" />
              </div>

              <div className="infoCart">
                <div className="containerImgPay">
                  <ImgUI style='imgPay' routeImg={iconTypeOfPay} />
                </div>

                <div className="containerTextPay">
                  <select className='customSelect' value={namePaymentMethod} onChange={handleChangePaymentMethod}>
                    
                    <option value="" disabled>Método de Pago</option>
                    <option value="tarjeta">Tarjeta de crédito/débito</option>
                    <option value="efectivo">Efectivo</option>
                  </select>
                </div>
              </div>


              <div className="confirmAndTotal">
                <ButtonUI style="btnOrder" text='Ordenar' onClicks={sendCartToServer} />
                <div className="totalPriceCart">
                  <p>TOTAL:</p><p className="priceTotal">${totalPrice}</p>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </div>
  );
};
