import React, { useState, useEffect } from 'react';
import { CounterCart } from "../CounterCart/CounterCart";
import { ButtonUI } from "../../UI/ButtonUI/ButtonUI";
import { ImgUI } from "../../UI/ImgUI/ImgUI";
import iconDeleteToCar from "../../../Images/deleteToCar.png";
import iconTypeOfPay from "../../../Images/typeOfPay.svg";
import io from "socket.io-client";

// const socket = io('https://backend-render-corp.onrender.com');

export const MainShoppingCart = ({ selectedProducts}) => {

  useEffect(() => {
    console.log(selectedProducts);
  }, []);

  return (
    <main className='mainShoppingCart'>
      <div className='contentShoppingCart'>
        <div className="containerTittleCart">
          <h2 className="titleShoppingCart">Su Orden</h2>
          <hr className="line" />
        </div>

        <div className='containerProductsShoppingCart'>
          
            <div  className='containerProductCart' >
              <div className="containerImageProduct">
                <ImgUI style="img" routeImg="pep" />
              </div>

              <div className="infoProduct">
                <p className="productName">"pepe"</p>
                <CounterCart />
              </div>

              <div className="containerButtonCart">
                <ButtonUI style="btnDeleteCart" text={<ImgUI style="deleteToCar" routeImg={iconDeleteToCar}></ImgUI>} />
                <p className="priceProduct">"pepe"</p>
              </div>
            </div>
          
        </div>

        <div className="containerLine">
          <hr className="line" />
        </div>

        <div className="infoCart" onClick={() => alert('hola')}>
          <ImgUI style='imgPay' routeImg={iconTypeOfPay} />
          <h3 className="infoPay">Seleccionar método de pago</h3>
        </div>

        <div className="confirmAndTotal">
          <ButtonUI style="btnOrder" text='Ordenar' />
          <div className="totalPriceCart">
            <p>TOTAL:</p><p className="priceTotal">$0.00</p>
          </div>
        </div>
      </div> 
    </main>
  );
};
