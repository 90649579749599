import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { reset } from '../../features/auth/authSlice';
import Swal from 'sweetalert2';
import axios from 'axios';
// UI
import { ButtonUI } from '../../UI/ButtonUI/ButtonUI';
import { InputUI } from '../../UI/InputUI/InputUI';


export const ContainerFormResetPass = () => {
  const { id, token } = useParams();
  const [formData, setFormData] = useState({
    password: '',
    passwordAuth: '',
  });

  const { password, passwordAuth } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      password,
    };

    axios
      .put(`https://backend-render-corp.onrender.com/reset-password/${id}/${token}`, userData)
      .then((response) => {
        // Manejar la respuesta exitosa
        console.log(response.data);
      })
      .catch((error) => {
        // Manejar el error
        console.log(error);
      });
  };

  return (
    <div className="campoDatos">
      <form onSubmit={onSubmit}>
        <div className="email">
          <InputUI
            typeInpt='text'
            idInpt='password'
            nameInpt='password'
            valueInpt={password}
            textInpt='Introduce Tu Contraseña'
            eventInpt={onChange}
          />
          <InputUI
            className='inputConfirm'
            typeInpt='passwordAuth'
            idInpt='passwordAuth'
            nameInpt='passwordAuth'
            valueInpt={passwordAuth}
            textInpt='Confirma Tu Contraseña'
            eventInpt={onChange}
          />
        </div>
        <ButtonUI typeBtn="submit" style='btnSendResetPass' text='Enviar' />
      </form>
      <Link className='back' to='/login'>Volver Al Inicio</Link>
    </div>
  );
};
