import React, { useState, useEffect, useContext } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import { ButtonUI } from '../../../UI/ButtonUI/ButtonUI';
import { ImgUI } from '../../../UI/ImgUI/ImgUI';
import { NotifyOrderContext } from '../ContentNotifications';
import iconDeleteToCar from '../../../../Images/deleteToCar.png';
import iconTypeOfPay from '../../../../Images/typeOfPay.svg';

const socket = io('https://backend-render-corp.onrender.com');

export const ContentNotifyOrder = ({ producto }) => {
  console.log(producto);
  const handleClose = useContext(NotifyOrderContext);

  return (
    <div className="modal">
      <ButtonUI style="closeModal" text="⨯" onClicks={handleClose} />

      <main className="mainShoppingCart">
        <div className="contentShoppingCart">
          <div className="containerTittleCart">
            <h2 className="titleShoppingCart">{`Orden Mesa #${producto.mesa}`}</h2>
            <hr className="line" />
          </div>
          {/* Mostrar el contenido de la orden aquí */}
          <div className="containerProductsShoppingCart">
            {producto.items.map((producto) => (
              <div key={producto.productId} className="containerProductCart">
                <div className="containerImageProduct">
                  <ImgUI style="img" routeImg={producto.image} />
                </div>

                <div className="containerButtonCart">
                  <p className="productName">Nombre: {producto.name}</p>
                </div>

                <div className="containerButtonCart">
                  <p className="productName">Cantidad: {producto.quantity}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="containerLine">
            <hr className="line" />
          </div>

          <div className="infoCart">
            <div className="containerImgPay">
              <ImgUI style="imgPay" routeImg={iconTypeOfPay} />
            </div>
            <div className="containerTextPayOrder">
              {producto.paymentMethod ? (
                <><p className="productName"> Metodo Pago:</p><p className='productPay'>{producto.paymentMethod}</p></>
              ) : (
                <h3 className="productName">No hay método de pago</h3>
              )}
            </div>
          </div>
          <div className="confirmAndTotal">
            <div className="totalPriceCart">
              <p>Precio Total: ${producto.totalAmount.toLocaleString()}</p>
            </div>
            <ButtonUI style="btnOrder" text={`Mesa #${producto.mesa}`} />
          </div>
        </div>
      </main>
    </div>
  );
};